import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import config from '../../data/SiteConfig';
import '../css/main.scss';
import MarkdownSeo from '../components/SEO/MarkdownSEO';

export default function WorkTemplate({ data }) {
  const workNode = data.markdownRemark;
  const work = workNode.frontmatter;
  const url = '/work';

  return (
    <Layout>
      <Helmet>
        <title>{`${work.title} | ${config.siteTitle}`}</title>
        <meta name="description" content={'${work.summary}'} />
      </Helmet>
      <MarkdownSeo postNode={workNode} url={url} />
      <div className="legacy">
        <div dangerouslySetInnerHTML={{ __html: workNode.html }} />
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query WorkBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        summary
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
